@import "../../../../styles/variables/media";

.container .avatar {
  position: relative;
  width: 250px;
  height: 250px;
  margin: 0 auto;
}

.container .rocket {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
}

.container .arc {
  z-index: 1;
  position: absolute;
  left: 59px;
  top: 44px;
  width: 105px;
  height: 105px;
  animation: rotateCircle 0.7s infinite linear;
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content > p,
.content > h5 {
  text-align: center;
}

.content h5 {
  font-size: 1.7rem;
}

.content p {
  font-size: 1.1rem;
}

.content a {
  display: inline !important;
}

@media only screen and (max-width: $brkPntSM) {
  .container .avatar {
    width: 200px;
    height: 200px;
  }

  .container .arc {
    left: 49px;
    top: 35px;
    width: 82px;
    height: 82px;
  }

  .content h5 {
    font-size: 1.4rem;
  }

  .content p {
    font-size: 0.95rem;
  }
}
