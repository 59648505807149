.metrics {
  position: absolute;
  top: 0.5px;
  right: 0;
  width: 5.5rem;
  transform: translateY(-100%);
  font-size: 0.7rem;
  font-weight: 500;
  text-align: right;
}

.resizer {
  position: absolute;
  right: 2px;
  top: 0;
  width: 7px;
  opacity: 0;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: col-resize;
}

.resizer:hover {
  opacity: 1;
}

.resizer > div {
  width: 100%;
  height: 20px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resizer > div svg {
  font-size: 0.8rem !important;
}
