.editor-content-external {
  line-height: 1.4;
  padding: 0.6rem;
  word-wrap: break-word;
  font-size: 14px;
  position: relative;

  address,
  article,
  aside,
  blockquote,
  div:not([data-mce-bogus]),
  dl,
  figcaption,
  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ol,
  li,
  p,
  pre,
  section,
  ul,
  strong {
    font-size: revert;
    font-weight: revert;
  }

  table {
    border-collapse: collapse;
  }
  table:not([cellpadding]) td,
  table:not([cellpadding]) th {
    padding: 0.4rem;
  }
  table[border]:not([border="0"]):not([style*="border-width"]) td,
  table[border]:not([border="0"]):not([style*="border-width"]) th {
    border-width: 1px;
  }
  table[border]:not([border="0"]):not([style*="border-style"]) td,
  table[border]:not([border="0"]):not([style*="border-style"]) th {
    border-style: solid;
  }

  blockquote {
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  a {
    color: #15a6a9;
    display: revert;
    text-decoration: revert;
  }

  pre[class*="language-"] {
    margin: 0.5em 0;
    overflow: auto;
    border-radius: 0.3em;

    font-family: monospace;
    padding: 5px;
  }

  .tox-checklist > li:not(.tox-checklist--hidden) {
    list-style: none;
    margin: 0.25em 0;
  }
  .tox-checklist > li:not(.tox-checklist--hidden)::before {
    content: url("https://cdn.tasksensor.com/icons/unchecked-checkbox.svg");
    cursor: pointer;
    height: 1em;
    margin-left: -1.5em;
    margin-top: 0.125em;
    position: absolute;
    width: 1em;
  }
  .tox-checklist li:not(.tox-checklist--hidden).tox-checklist--checked::before {
    content: url("https://cdn.tasksensor.com/icons/checked-checkbox.svg");
  }
  [dir="rtl"] .tox-checklist > li:not(.tox-checklist--hidden)::before {
    margin-left: 0;
    margin-right: -1.5em;
  }

  hr {
    border-style: solid;
    border-width: 1px 0 0 0;
  }

  .mention {
    border-radius: 5px;
    padding: 0 3px;
  }
}

.editor-content-external.dark {
  table[border]:not([border="0"]):not([style*="border-color"]) td,
  table[border]:not([border="0"]):not([style*="border-color"]) th {
    border-color: #6d737b;
  }

  blockquote {
    border-left: 2px solid #6d737b;
  }

  pre[class*="language-"] {
    background-color: #2f373f;
    outline: 1px solid #434c4f;
  }

  hr {
    border-color: #6d737b;
  }

  .mention {
    background-color: #384550;
  }
}

.editor-content-external.light {
  table[border]:not([border="0"]):not([style*="border-color"]) td,
  table[border]:not([border="0"]):not([style*="border-color"]) th {
    border-color: #ccc;
  }

  blockquote {
    border-left: 2px solid #ccc;
  }

  pre[class*="language-"] {
    background-color: #f0f0f3;
    outline: 1px solid #e1e4e5;
  }

  hr {
    border-color: #ccc;
  }

  .mention {
    background-color: rgb(225, 232, 243);
  }
}
