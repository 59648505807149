@import "src/common/styles/variables/layout";

.container {
  height: $horizontalTaskHeight;
  position: absolute;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
}

.content {
  font-size: $offtimeFontSize;
}
.content > div {
  text-align: center;
}
