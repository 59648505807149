@import "src/common/styles/variables/layout";

.container {
  height: $horizontalTaskHeight;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

// The state class is defined for TaskHorizontal and can be redefined for other kinds as well
.state {
  transform: translateY(-50%);
  height: 29px;
  width: 3px;
  border-radius: 2px;
}

.state-icon {
  --icon-size: 1rem;
  width: var(--icon-size);
  height: var(--icon-size);
  margin-left: -0.5px;
}

.state-icon svg {
  font-size: var(--icon-size) !important;
}

.metadata {
  font-size: 0.65rem;
}
