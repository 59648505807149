.container {
  padding: 8px 22px;

  border-radius: 10px;
  cursor: move;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  width: 150px;
}
