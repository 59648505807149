.form > div {
  margin-bottom: 1rem;
}

.actions-row {
  padding-top: 1rem;
}
.actions-row button {
  margin-left: 8px;
}
.actions-row button:first-child {
  margin-left: 0;
}

.text-align-right {
  text-align: right;
}
.text-align-left {
  text-align: left;
}
