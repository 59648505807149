.iconWrapper {
  cursor: pointer;
  transition: transform 0.2s; /* Smooth transition for interaction */
}

.iconImg {
  display: block;
  border: 2px solid transparent; /* Default state without border */
  border-radius: 5px; /* Rounded corners for the icon */
  padding: 3px;
}

.iconImgSelected {
  border-color: #4c9aff; /* Blue border for selected icon */
  box-shadow: 0 0 0 4px rgba(76, 154, 255, 0.3); /* Outer glow effect */
  transform: scale(1.1); /* Slightly scale up the selected icon */
}
