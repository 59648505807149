.content {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.icon {
  position: absolute;
  left: -25px;
  top: 15px;
}

.inner-container {
  position: relative;
  border-radius: 14px;
  width: 370px;
  height: 180px;
  padding: 14px 14px 14px 37px;
}

.inner-content {
  border-radius: 14px;
  width: 100%;
  height: 100%;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 15px 15px 15px 95px;
}

.data .description {
  padding-left: 7px;
}

.data .button {
  padding-left: 27px;
}
