@import "src/common/styles/variables/media";

.container {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}
.container > div {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: $brkPntSM) {
  .container {
    gap: 0.2rem;
  }
}
@media only screen and (max-width: $brkPntLG) {
  .container {
    gap: 0.3rem;
  }
  .task {
    width: 60%;
  }
}
