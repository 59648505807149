@import "src/common/styles/variables/media";

.container p {
  font-size: 1rem;
}

@media only screen and (max-width: $brkPntSM) {
  .container p {
    font-size: 0.9rem;
  }
}
