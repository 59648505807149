.icon {
  cursor: pointer;
}

.xs {
  font-size: 0.8rem !important;
}

.sm {
  font-size: 1rem !important;
}

.md {
  font-size: 1.2rem !important;
}
