.title {
  color: var(--clr-menu-title);
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 1px;
  padding: 25px 0 8px 20px;
  text-transform: capitalize;
}

.ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.title div {
  position: relative;
}

.title div > .action-1-icon {
  position: absolute;
  right: calc(15px - 0.75rem);
  top: 50%;
  transform: translateY(-50%);
  margin-left: 3px;
  font-size: 1rem;
  transition: opacity 0.3s linear;
  color: var(--clr-menu-title-actions);
  cursor: pointer;
  padding: 0.75rem;
}

.title div > .loading-icon {
  position: absolute;
  right: calc(27px - 0.75rem);
  font-size: 1.2rem;
  color: var(--clr-menu-title-actions);
}
