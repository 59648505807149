.videoContainer {
  border-radius: 12px;
  position: relative;
  aspect-ratio: 1834 / 1080;

  > .poster,
  > video {
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@keyframes next1 {
  from {
    left: 0%;
  }
  to {
    left: -100%;
  }
}

@keyframes next2 {
  from {
    left: calc(100% + 16px);
  }
  to {
    left: 0%;
  }
}

@keyframes prev1 {
  from {
    left: 0%;
  }
  to {
    left: 100%;
  }
}

@keyframes prev2 {
  from {
    left: calc(-100% - 16px);
  }
  to {
    left: 0%;
  }
}
