.description {
  cursor: pointer;
  margin-bottom: 8px;
  overflow: hidden;
  position: relative;
}

.description-placeholder {
  color: #808080;
}

.description-collapse-expand-block {
  bottom: 0;
  cursor: default;
  height: 76px;
  padding-left: 30px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
}
