.container {
  --bold: 700;

  display: flex;
  align-items: center;
  margin-bottom: 35px;
}

.pointer {
  position: relative;
  text-align: center;
  font-size: 0.7rem;
  color: var(--clr-muted);
}
.pointer.light {
  border-left: 1px solid var(--clr-default);
}
.pointer.dark {
  border-left: 1px solid #68707a;
}
.pointer:first-child {
  border-left: none;
}

.now {
  color: var(--clr-orange) !important;
  font-weight: var(--bold);
}

.start {
  color: var(--clr-success) !important;
  font-weight: var(--bold);
}

.due {
  color: var(--clr-danger) !important;
  font-weight: var(--bold);
}
