.now {
  --rgb-color: 255 160 0; /* orange */
}

.start {
  --rgb-color: 57, 161, 131, 1; /* green */
}

.due {
  --rgb-color: 244, 81, 30, 1; /* red */
}

.checkpoint {
  --rgb-color: 79 195 247; /* blue */
}

.arrow {
  color: rgb(var(--rgb-color));
}

.box {
  background-color: rgb(var(--rgb-color));
}

.lineNow {
  background-color: rgb(var(--rgb-color));
  height: 100%;
  width: 1px;
}

.lineDashed {
  height: 100%;
  width: 1px;
  background: repeating-linear-gradient(
    to bottom,
    rgb(var(--rgb-color)) 0,
    rgb(var(--rgb-color)) 4px,
    transparent 0,
    transparent 10px
  );
  background-size: 1px 100%;
  background-repeat: no-repeat;
}

.shadow {
  --inset-shadow: inset 0px 0px 1px 0px rgb(var(--rgb-color) / 13%);
  --outset-shadow: 0px 0px 8px 0px rgb(var(--rgb-color) / 40%);
  -webkit-box-shadow: var(--inset-shadow), var(--outset-shadow);
  box-shadow: var(--inset-shadow), var(--outset-shadow);
}
