.progress-bar {
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  background-color: var(--bg-progress-bar);
  border-radius: 0.25rem;
}

.bg-transparent {
  background-color: transparent;
}

.size-xxs {
  height: 0.175rem;
  border-radius: 0.12rem;
}

.size-xs {
  height: 0.375rem;
  border-radius: 0.12rem;
}

.size-sm {
  height: 0.75rem;
  border-radius: 0.2rem;
}

.size-md {
  height: 1rem;
}
.size-lg {
  height: 1.5rem;
}
.size-xl {
  height: 1.875rem;
}

.bar {
  transition: width 0.3s linear;
}
