@import "src/common/styles/variables/media";

.container img {
  width: 250px;
  height: 250px;
  margin-bottom: 25px;
  margin-top: 140px;
}

.container h5 {
  font-size: 1.7rem;
}

@media only screen and (max-width: $brkPntSM) {
  .container img {
    width: 190px;
    height: 190px;
    margin-top: 50px;
  }

  .container h5 {
    font-size: 1.4rem;
  }
}
