@import "src/common/styles/variables/media";

.container img {
  width: 250px;
  height: 250px;
  margin-bottom: 18px;
  margin-top: 140px;
}

.container p {
  font-size: 1.3rem;
}

@media only screen and (max-width: $brkPntSM) {
  .container img {
    width: 190px;
    height: 190px;
    margin-top: 70px;
  }

  .container p {
    font-size: 1rem;
  }
}
