.key {
  display: inline-flex;
  padding: 0 0.3em;
  border-radius: 0.25em;
  align-items: center;
  justify-content: center;
  line-height: 1.5em;
  font-size: 0.76rem;
  font-weight: bold;
  user-select: none;
}
