@import "src/common/styles/variables/layout";

.container {
  border-radius: 12px;
}

.content {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 10px;
  display: flex;
}

.ref {
  font-size: $taskRefFontSize;
  font-weight: 600;
  transform: rotate(-90deg);
  white-space: nowrap;
}
.push-aside {
  // Padding top to receive left padding since the content is -90deg rotated
  padding-top: 0.8rem;
}
.name {
  font-size: $taskDescFontSize;
  padding: 0 3px;
  height: 2rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
