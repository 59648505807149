.file-drop-zone {
  padding: 10px;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.file-drop-zone > div {
  padding: 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-drop-zone > div > img {
  width: 50px;
  height: 50px;
}
