@import "src/common/styles/variables/layout";

.container {
  border-radius: 12px;
  height: $horizontalTaskHeight;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  width: calc(100% - 3px);
  height: calc(100% - 3px);
  border-radius: 10px;
  position: relative;
}

.ref {
  font-size: $taskRefFontSize;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
}

.topInfo {
  font-size: $taskRefFontSize;
  padding: 5px 12px 0 12px;
  overflow: hidden;
}

.boardName {
  font-weight: 600;
}

.right {
  float: right;
}

.left {
  float: left;
}

.push-aside {
  padding-left: 1.7rem;
}

.metadata {
  width: 100%;
  font-size: 0.65rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
