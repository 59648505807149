@import "src/common/styles/variables/media";
@import "src/common/styles/variables/layout";

:root {
  --border-radius: 5px;

  /* menu variables */
  --clr-menu-title: #92989b;
  --clr-menu-title-actions: #c2c2c2;
  --clr-navigation: #d7dadb;

  --bg-progress-bar: #e9ecef;

  --clr-muted: #919aa3;

  --clr-white: #ffffff;

  --clr-blue: #0d6efd;

  --clr-default: #e0e0e0;
  --clr-default-l: #f3f3f3;
  --clr-default-l2: #e8e8e8;

  --clr-orange: #ffa000;
  --clr-orange-l: #ff6f00;

  --clr-green: #0ac282;
  --clr-green-l: #0df3a3;

  --clr-pink: #fe5d70;
  --clr-pink-l: #fe909d;

  --clr-blue-l: #01a9ac;
  --clr-blue-xl: #01dbdf;

  --clr-primary: var(--clr-blue-l);
  --clr-primary-l: #00d0d2;

  --clr-success: var(--clr-green);
  --clr-success-l: #0ede91;

  --clr-info: #2dcee3;
  --clr-info-l: #48e0f8;

  --clr-warning: var(--clr-orange);
  --clr-warning-l: #ffad8d;

  --clr-danger: var(--clr-pink);
  --clr-danger-l: #fd8d99;

  --clr-disabled: #a7abaf;
  --clr-disabled-l: #bebec0;

  --clr-input-border: rgba(0, 0, 0, 0.25);
  --clr-input-border-outline: #86b7fe;
  --clr-input-box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  --clr-container-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);

  --fs-main: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--fs-main);
  // Remove for fullscreen dialogs
  // padding-right: unset !important;
  // overflow: unset !important;
}

body.modal-open {
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

#notification-root {
  left: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  width: 100%;
}

/***************************/
/* GENERAL TAGS DEFINITION */

a {
  text-decoration: none;
  background-color: transparent;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 0.875rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 0.875rem;
}

@media only screen and (max-width: $brkPntMD) {
  h4,
  .h4 {
    font-size: 1rem;
    font-weight: 600;
  }
}

/******************************/
/* COLORING FOR ENTIRE SYSTEM */

.bg-orange {
  background-color: var(--clr-orange) !important;
}
.bg-orange-g {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--clr-orange)),
    to(var(--clr-orange-l))
  );
  background: linear-gradient(to right, var(--clr-orange), var(--clr-orange-l));
}

.bg-green {
  background-color: var(--clr-green) !important;
}
.bg-green-g {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--clr-green)),
    to(var(--clr-green-l))
  );
  background: linear-gradient(to right, var(--clr-green), var(--clr-green-l));
}

.bg-pink {
  background-color: var(--clr-pink) !important;
}
.bg-pink-g {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--clr-pink)),
    to(var(--clr-pink-l))
  );
  background: linear-gradient(to right, var(--clr-pink), var(--clr-pink-l));
}

.bg-blue-l {
  background-color: var(--clr-blue-l) !important;
}
.bg-blue-l-g {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--clr-blue-l)),
    to(var(--clr-blue-xl))
  );
  background: linear-gradient(to right, var(--clr-blue-l), var(--clr-blue-xl));
}

.bg-default {
  background-color: var(--clr-default) !important;
}
.bg-default-g {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--clr-default)),
    to(var(--clr-default-l))
  );
  background: linear-gradient(
    to right,
    var(--clr-default),
    var(--clr-default-l)
  );
}

.text-white {
  color: var(--clr-white) !important;
}
.text-muted {
  color: var(--clr-muted) !important;
}
.text-orange {
  color: var(--clr-orange) !important;
}
.text-green {
  color: var(--clr-green) !important;
}
.text-pink {
  color: var(--clr-pink) !important;
}
.text-blue-l {
  color: var(--clr-blue-l) !important;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/********************/
/* SYSTEM UTILITIES */

.m-b-0 {
  margin-bottom: 0;
}
.m-b-5 {
  margin-bottom: 5px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-15 {
  margin-bottom: 15px;
}
.m-t-10 {
  margin-top: 10px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-5 {
  margin-right: 5px;
}
.m-r-10 {
  margin-right: 10px;
}

.p-0 {
  padding: 0 !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}

.f-w-600 {
  font-weight: 600;
}
.f-w-700 {
  font-weight: 700;
}
.f-40 {
  font-size: 40px;
}

.d-block {
  display: block;
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-cap {
  text-transform: capitalize;
}

.b-t-g {
  border-top: 1px solid var(--clr-default) !important;
}
.b-b-g {
  border-bottom: 1px solid var(--clr-default) !important;
}
.b-t-w {
  border-top: 1px solid var(--clr-white) !important;
}
.b-r-g {
  border-right: 1px solid var(--clr-default) !important;
}

/******************/
/* Content Loader */
#content-loader {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10000000;
}

.no-select {
  user-select: none;
}

/* Pulsating Animation */
.pulsating {
  animation: pulsate-opacity 0.8s infinite linear;
}

@keyframes pulsate-opacity {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
