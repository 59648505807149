.icon {
  z-index: 1;
}
.icon:hover {
  z-index: 2;
}

.menu-icon {
  cursor: pointer;
  height: 22px;
  width: 22px;
}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
