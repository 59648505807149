.container {
  --task-lane-height: 108px;

  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: clip;
}

.task-lane {
  height: var(--task-lane-height);
  display: flex;
  align-items: center;
  min-width: 100%;
  z-index: 10;
  position: relative;
}
