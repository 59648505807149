@import "src/common/styles/variables/layout";

.container {
  border-radius: 12px;
}

.content {
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  border-radius: 10px;
  position: relative;
}

.ref {
  font-size: $taskRefFontSize;
  font-weight: 600;
  padding: 0.2rem 4px 0 4px;
  overflow: hidden;
  white-space: nowrap;
}

.metadata {
  width: 100%;
  font-size: 0.65rem;
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
