.animate::after {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0)
  );
  transform: skewX(-25deg);
  animation: shine 0.8s linear infinite;
}

@keyframes shine {
  from {
    left: -50%;
  }
  to {
    left: 150%;
  }
}
