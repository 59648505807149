.content {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.inner-container {
  position: relative;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  padding: 12px 12px 12px 100px;
}

.inner-content {
  border-radius: 14px;
  width: 100%;
  height: 100%;
}

.icon {
  position: absolute;
  left: -25px;
  top: -20px;
}

.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 12px 12px 12px 25px;
}
