@import "src/common/styles/variables/media";

.container {
  width: 100%;
  padding-top: 80px;
}

.content {
  background-position: top left;
  background-repeat: no-repeat !important;
  background-size: auto;
  border-radius: 5px;
  margin: 0 auto;
  padding-top: 50px;
  width: 970px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}

.form-content {
  padding: 0 7%;
  text-align: center;
  width: 60% !important;
}

@media only screen and (max-width: $brkPntLG) {
  .content {
    width: 95%;
  }

  .form-content {
    width: 55% !important;
  }
}

@media only screen and (max-width: $brkPntMD) {
  .container {
    padding-top: 0;
  }

  .content {
    background-size: contain !important;
    border-radius: 0;
    padding: 230px 0 24px;
    width: 100%;
    display: block;
  }

  .form-content {
    width: 100% !important;
  }
}

@media only screen and (max-width: $brkPntSM) {
  .content {
    padding-top: 170px;
  }
}
