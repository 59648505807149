@import "../../../../../styles/variables/layout";

.navigation {
  background: var(--bg-navigation);
  color: var(--clr-navigation);
  position: fixed;
  height: 100%;
  // padding-bottom: $headerHeight + $navigationBottomSectionHeight + 30px;
  z-index: 10;
}

.board-item-padding-bottom {
  padding-bottom: 8px !important;
}
