.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  background-color: #f5f7f8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
}

.closeIcon {
  position: absolute !important;
  right: 10px;
  top: 10px;
}
