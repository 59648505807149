.container {
  --side-view-width: 310px;
  --side-view-radius: 4px;

  display: flex;
}

.side-view {
  border-top-left-radius: var(--side-view-radius);
  border-bottom-left-radius: var(--side-view-radius);
  border-bottom-right-radius: var(--side-view-radius);
  width: var(--side-view-width);
  flex-basis: var(--side-view-width);
  padding: 0 10px 10px 15px;
}
.side-view > div:first-child {
  text-align: right;
}
.side-view > div:first-child svg {
  color: var(--clr-muted);
  font-size: 1.2rem;
}

.content {
  width: 100%;
}

.content.right-handed {
  width: calc(100% - var(--side-view-width));
}
