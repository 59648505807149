@import "../../../../../../common/styles/variables/layout";

.container {
  height: 94px;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
