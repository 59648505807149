@import "../../../../../../common/styles/variables/media";

.left-container {
  flex: 1;
  display: flex;
  gap: 0.5rem;
}
.left-container > div {
  display: flex;
  align-items: center;
}

.right-container {
  flex: 1;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}
.right-container > div {
  display: flex;
  align-items: center;
}

.ref {
  white-space: nowrap;
}

.name {
  line-height: 1.3rem;
  height: 1.3rem;
  width: 90%;
  overflow: hidden;
  align-items: start !important;
  cursor: pointer;
}

@media only screen and (max-width: $brkPntSM) {
  .left-container {
    gap: 0.2rem;
  }
}

@media only screen and (max-width: $brkPntLG) {
  .left-container {
    gap: 0.3rem;
  }
  .name {
    width: 60%;
  }
}
