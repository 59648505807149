.title {
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.title:hover {
  background-color: #f0f0f00f;
  transition: background-color 0.3s ease;
}

.form-margin {
  margin-bottom: 0 !important;
}
