.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  margin: 0 auto;
  text-align: center;
  color: #959494;
  font-size: 0.8rem;
}

.container:before,
.container:after {
  content: "";
  flex: 1;
  border-bottom: 1px dotted #595858;
}

.container:before {
  margin-right: 1em;
}

.container:after {
  margin-left: 1em;
}
